<template>
    <div>
  

      <CCard>
        <CCardHeader>
            <h2><b>{{ getCandidateName }}</b></h2>
        </CCardHeader>

        <CCardBody>
          <Legend :count="count" :total="total" />       
        </CCardBody>

        <CCardFooter class="text-center">
           <CRow>
            <template v-for="(question, idx) in questionList">
              <CCol
                :key="idx"
                sm="1"
                col="2"
                lg="2"
                class="p-0 d-flex justify-content-center"
              >
                <div
                  :class="{
                    answered: question.tags === 'Answered',
                    visited: question.tags === 'Visited',
                    'answered-marked': question.tags === 'AnsweredMarked',
                    marked: question.tags === 'Marked',
                  }"
                  class="custom-box number-box text-center hover-question"
                  @click="getQuestion(idx)"
                >
                  {{ (idx + 1).toString() }}
                </div>
              </CCol>
            </template>
          </CRow>
        </CCardFooter>
      </CCard>
    </div>
</template>
<script>
import Legend from "./Legend";
export default {
    name:"QuestionNumberPanel",
    components:{
        Legend
    },
    props:{
        count:Object,
        total:Number,
        questionList:Array,
        getQuestion:Function,
    },
    computed:{
      getCandidateName(){
        let examData = JSON.parse(localStorage.getItem('examData'));
        let candidateDetails = examData.candidateDetails;
        return candidateDetails.candidateName;
      }
    }
    
}
</script>
<style>
.custom-box {
  height: 35px;
  width: 35px;
  margin: 0.25rem;
  border-radius: 5px;
}

.number-box {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #232375;
  font-weight: bold;
  background: #a2a4a9;
}
.answered {
  background-color: #45a664;
  color: white;
}

.visited {
  background-color:rgb(49, 39, 95);
  color: white;
}

.not-visited {
  background: #a2a4a9;
}

.marked {
  background-color: #eb624b;
  color: #ffff;
}

.answered-marked {
  background-color: #6b60cd;
  color: white;
}
@media screen and (max-width: 960px) {
  .hide-this-submit {
    display: none;
  }
}

</style>