<template>
  <div>
    <div v-html="getQuestionData('instructions')" class="disable-select"></div>

    <div
      v-html="getQuestionData('questionStatement')"
      class="disable-select"
    ></div>

    <template v-for="(option, key) in getQuestionData('options')">
      <CRow align-vertical="center" :key="key" class="option-box">
        <CCol col="1">
          <input
            type="radio"
            :id="key"
            :value="key"
            :checked="value === key"
            @change="updateResponse"
            :disabled=previewAnswer
          />
        </CCol>
        <CCol col="6">
          <label
            v-if="previewAnswer == true && key == getCorrectAnswer"
            :for="key"
            v-html="option + correctSVG"
            class="disable-select"
          ></label>
          <label
            v-else
            :for="key"
            v-html="option"
            class="disable-select"
          ></label>
        </CCol>
      </CRow>
    </template>
    <CRow  v-if="previewAnswer == true ">
      <CCol col="12">
        <CCard>
          <CCardHeader> Explaination </CCardHeader>
          <CCardBody v-html="getQuestionData('solution')"> </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "QuestionTab",
  data() {
    return {
      correctSVG:
        ' <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="c-icon"><path fill="var(--ci-primary-color, currentColor)" d="M199.066,456l-7.379-7.514-3.94-3.9-86.2-86.2.053-.055L17.936,274.665l97.614-97.613,83.565,83.565L398.388,61.344,496,158.958,296.729,358.229,285.469,369.6ZM146.6,358.183l52.459,52.46.1-.1.054.054,52.311-52.311,11.259-11.368L450.746,158.958,398.388,106.6,199.115,305.871,115.55,222.306,63.191,274.665l83.464,83.463Z" class="ci-primary"></path></svg>',
    };
  },
  props: {
    currentQuestionData: Object,
    value: String,
  },
  computed: {
    getCorrectAnswer() {
      return JSON.parse(this.currentQuestionData["correctAnswer"])[0];
    },
    previewAnswer :{
      set(value){
        this.previewAnswer = JSON.parse(sessionStorage.getItem("previewAnswer") );
      },
      get(){
        return  JSON.parse(sessionStorage.getItem("previewAnswer") );
        
      }
      
    }
  },
  methods: {
    updateResponse(e) {
      this.$emit("input", e.target.value);
    },
    getQuestionData(prop) {
      return this.currentQuestionData[prop];
    },
  },
};
</script>

<style scoped>
.option-box {
  padding: 10px;
}

.disable-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>