<template>
  <CRow align-vertical="center">
    <CCol col="2">
      <div class="custom-box answered"></div>
    </CCol>
    <CCol col="4" class="text-left p-0">
      Answered [<strong class="">{{ count.answered }}</strong>]
    </CCol>
    <CCol col="2">
      <div class="custom-box visited"></div>
    </CCol>
    <CCol col="4" class="text-left p-0">
      Visited [<strong>{{ count.visited }}</strong>]
    </CCol>
    <CCol col="2">
      <div class="custom-box not-visited"></div>
    </CCol>
    <CCol col="10" class="text-left p-0">
      Not visited [<strong>{{ total - count.visited }}</strong>]
    </CCol>
    <CCol col="2">
      <div class="custom-box answered-marked"></div>
    </CCol>
    <CCol col="10" class="text-left p-0">
      Answered and Marked for Review [<strong>{{ count.answeredMarked }}</strong>]
    </CCol>
    <CCol col="2">
      <div class="custom-box marked"></div>
    </CCol>
    <CCol col="10" class="text-left p-0">
      NOT Answered and Marked for Review [<strong>{{ count.marked }}</strong>]
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "Legend",
  props: {
    count: Object,
    total: Number,
  }
}
</script>
