<template>
  <CRow class="position-relative overflow-hidden">
    <CCol lg="8" col="12">
      <CCard>
        <CCardBody class="question-tab">
          <CTabs
            :active-tab.sync="tabIndex"
            variant="tabs"
            fill
            addNavClasses="bold"
          >
            <template v-for="(mod, idx) in moduleData">
              <CTab :key="idx" :title="mod.moduleName">
                <CRow align-vertical="center" class="question-bar">
                  <CCol col="1" class="p-0"
                    >Q{{ (currentQuestionIdx + 1).toString() }}
                  </CCol>

                  <CCol col="4" class="p-0">
                    <b>Marks </b>
                    <span class="text-success">
                      +{{ currentQuestion.questionMarks.positiveMarks }}
                    </span>
                    :
                    <span class="text-danger">
                      -{{ currentQuestion.questionMarks.negativeMarks }}</span
                    >
                  </CCol>

                  <CCol col="4" class="p-0">
                    <div>
                      <b>Time taken</b> <span>{{ formatTime(mins) }}</span>
                      :
                      <span>{{ formatTime(secs) }}</span>
                    </div>
                  </CCol>

                  <CCol col="3" class="p-0">
                    <CSelect
                      label=""
                      horizontal
                      :value.sync="currentLangCode"
                      :options="langList"
                    />
                  </CCol>
                </CRow>

                <QuestionTab
                  v-model="answer"
                  :currentQuestionData.sync="getCurrentLangQuestion"
                />
              </CTab>
            </template>
          </CTabs>
        </CCardBody>
        <CCardFooter>
          <CRow class="justify-content-end">
            <CCol sm="3" class="d-flex mb-2">
              <CButton color="primary" @click="markOption" class="flex-grow-1"
                ><b>Mark for Review</b></CButton
              >
            </CCol>
            <CCol class="text-left d-flex mb-2" sm="3">
              <CButton color="danger" @click="clearResponse" class="flex-grow-1"
                ><b>Clear Response</b></CButton
              >
            </CCol>
            <CCol class="d-flex mb-2" sm="3">
              <CButton color="info" @click="submitOption" class="flex-grow-1"
                ><b>Save &amp; Next</b></CButton
              >
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>

    <CCol
      lg="4"
      class="panel-hidden p-0"
      :class="{ 'panel-screen': panelToggler }"
    >
      <QuestionNumberPanel
        :count="legend"
        :total="getTotalQuestionCount"
        :questionList.sync="questionList"
        :getQuestion="getQuestion"
      />
    </CCol>
    <CButton
      class="ml-3 d-lg-none panel-toggler"
      @click="panelToggler = !panelToggler"
    >
      <CIcon :name="panelToggler ? 'cilChevronRight' : 'cilChevronLeft'" />
    </CButton>
    <CModal
      title="Submit Exam Alert"
      color="danger"
      :show.sync="$store.state.warning"
      :centered="true"
    >
      <h5><b> Are sure you want to submit the Exam?</b></h5>

      <div slot="footer">
        <CButton color="danger" @click="submitExam">Submit Exam</CButton>
      </div>
    </CModal>
  </CRow>
</template>

<script>
import { ajaxCallMixin } from "@/mixins/HttpCommon";
import { localDb } from "@/mixins/localDb";
import { proctoring } from "@/mixins/proctoring";
import QuestionTab from "./components/QuestionTab";
import QuestionNumberPanel from "./components/QuestionNumberPanel";
import { mapGetters } from "vuex";

export default {
  name: "ExamPaper",
  mixins: [ajaxCallMixin, localDb, proctoring],
  components: {
    QuestionTab,
    QuestionNumberPanel,
  },
  data() {
    return {
      questionData: {},
      moduleData: {},
      currentLangCode: "en",
      currentQuestionIdx: 0,
      currentQuestion: {},
      questionList: [],
      currentModule: 0,
      tabIndex: 0,
      langDropDown: false,
      langList: [],
      previewAnswer : this.get("previewAnswer","session"),
      legend: {
        answered: 0,
        visited: 0,
        answeredMarked: 0,
        marked: 0,
      },
      answer: "",
      mins: 0,
      secs: 0,
      tickTockTime: Function,
      panelToggler: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    const examQuestionData = JSON.parse(
      sessionStorage.getItem("moduleQuestion")
    );
    const status = JSON.parse(sessionStorage.getItem("isExamInProgress"));
    if (!status) next({ path: "/exam/feedback" });
    else if (!examQuestionData) next({ path: "/exam/details" });
    else next();
  },
  beforeRouteLeave(to, from, next) {
    this.saveState();
    if (to.name === "ExamDetails") next(false);
    else next();
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.saveState);
    window.addEventListener("blur", () => {
      let offFocusCount = this.examSettings.offFocusCount;
      if (offFocusCount > 1) {
        offFocusCount - 1 === 0 ? this.submitExam() : offFocusCount--;
        this.examSettings.offFocusCount = offFocusCount;
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.saveState);
  },
  mounted() {
    this.getExamPaperData();
    this.langSelectEnable();
    this.$store.commit("setTimer", true);
  },
  watch: {
    tabIndex: function () {
      this.currentModule = parseInt(this.moduleData[this.tabIndex].moduleId);
      this.questionList = this.questionData[this.currentModule]["en"];
      this.getQuestion(0);
    },
    isExamInProgress: function () {
      if (!this.isExamInProgress) {
        this.submitExam();
      }
    },
  },
  computed: {
    ...mapGetters({
      timer: "timer",
      isExamInProgress: "isExamInProgress",
    }),
    getCurrentLangQuestion() {
      let questionList = this.questionData[this.currentModule][
        this.currentLangCode
      ];
      return questionList[this.currentQuestionIdx];
    },
    getTotalQuestionCount() {
      let total = 0;
      Object.values(this.questionData).forEach((mod) => {
        total += mod["en"].length;
      });
      return total;
    },
  },
  methods: {
    getExamPaperData() {
      const examQuestionData = this.get("moduleQuestion", "session");
      this.moduleData = examQuestionData.moduleData;
      //<!----get from session storage if page is reloaded
      this.legend = this.get("legend", "session") || this.legend;
      this.questionData = this.get("questionData", "session") || examQuestionData.questionData;
      //------>
      this.currentModule = parseInt(this.moduleData[0].moduleId);
      this.questionList = this.questionData[this.currentModule]["en"];
      this.getQuestion(0);
    },
    getQuestion(idx) {
      if (this.panelToggler) this.panelToggler = false; //for mobile layout

      this.currentQuestion.timeTaken = this.mins * 60 + this.secs;
      this.currentQuestionIdx = idx;
      this.currentQuestion = this.questionList[idx];
      if (!this.currentQuestion.tags) {
        this.currentQuestion.questionMarks = JSON.parse(
          this.currentQuestion.questionMarks
        );
        if(this.previewAnswer){
          this.questionList[idx].tags = this.currentQuestion.tags;
        }
        else if( !this.previewAnswer ){
          this.questionList[idx].tags = this.currentQuestion.tags = "Visited";
          this.legend.visited++;
        }
        this.answer = "";
        this.tickTock(0, 0);
      } else {
        let time = this.currentQuestion.timeTaken;
        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        this.tickTock(minutes, seconds);
        this.answer = this.currentQuestion.response || "";
      }
    },
    submitOption() {
      
      if (this.answer !== "" && !this.previewAnswer ) {
        this.decreaseCount(["AnsweredMarked", "Marked"]);
        this.currentQuestion.tags = "Answered";
        this.legend.answered++;
        this.currentQuestion.response = this.answer;
      }
      this.goToNext();
    },
    markOption() {
      if (this.answer !== "" && !this.previewAnswer ) {
        this.decreaseCount(["Answered", "Marked"]);
        this.currentQuestion.tags = "AnsweredMarked";
        this.legend.answeredMarked++;
        this.currentQuestion.response = this.answer;
      } else if(!this.previewAnswer ){
        this.currentQuestion.tags = "Marked";
        this.legend.marked++;
      }
      this.goToNext();
    },
    
    clearResponse() {
      if (this.answer !== "" && !this.previewAnswer ) {
        this.decreaseCount(["AnsweredMarked", "Answered", "Marked"]);
        this.currentQuestion.tags = "Visited";
        this.currentQuestion.response = "";
        this.answer = "";
      }
    },
    decreaseCount(tags) {
      const tagToKey = {
        Answered: "answered",
        AnsweredMarked: "answeredMarked",
        Marked: "marked",
      };
      tags.forEach((tag) => {
        if (tag === this.currentQuestion.tags) {
          this.legend[tagToKey[tag]]--;
        }
      });
    },
    goToNext() {
      if (this.currentQuestionIdx + 1 === this.questionList.length) {
        let totalModules = this.moduleData.length;
        this.tabIndex + 1 < totalModules
          ? this.tabIndex++
          : (this.confirmationModal = true);
      } else this.getQuestion(this.currentQuestionIdx + 1);
    },
    tickTock(mins, secs) {
      this.secs = secs || 0;
      this.mins = mins || 0;
      clearInterval(this.tickTockTime);
      this.tickTockTime = setInterval(() => {
        if (this.secs === 59) {
          this.secs = 0;
          this.mins += 1;
        } else {
          this.secs += 1;
        }
      }, 1000);
    },
    submitExam() {
      this.stopInputStream();

      let candidateResponse = {};
      for (let key of Object.keys(this.questionData)) {
        candidateResponse[key] = [];
        for (let i = 0; i < this.questionData[key]["en"].length; i++) {
          let obj = {
            questionId: this.questionData[key]["en"][i].questionId,
            timeTaken: this.questionData[key]["en"][i].timeTaken || 0,
            answerMarked: this.questionData[key]["en"][i].response
              ? [this.questionData[key]["en"][i].response]
              : [],
          };
          candidateResponse[key].push(obj);
        }
      }
      this.save("candidateResponse", candidateResponse, "session");
      this.save("offFocusCount", this.offFocusCount, "session");
      this.save("isExamInProgress", false, "session");
      this.$store.state.warning = false;
      /*this.remove("moduleQuestion","session");
      this.remove("questionCount","session");
      this.remove("questionData","session");*/
      if (this.timer) this.$store.commit("setTimer", false);
      if (this.isExamInProgress) this.$store.commit("setExamStatus", false);
      this.$router.push("/exam/feedback");
    },
    saveState() {
      this.currentQuestion.timeTaken = this.mins * 60 + this.secs;
      this.save("questionData", this.questionData, "session");
      this.save("legend", this.legend, "session");
      this.$store.commit("setTimer", false);
    },

    langSelectEnable() {
      let questionLang = Object.keys(this.questionData[this.currentModule]);
      this.langList = questionLang;
      this.currentLangCode = questionLang[0];

      if (questionLang.length < 2) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.hover-question {
  cursor: pointer;
}

.question-tab {
  min-height: 516px;
}

.question-bar {
  border-bottom: solid 2px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 10px;
}
.panel-toggler {
  position: absolute;
  right: 0;
  bottom: 50%;
  background: #31275f;
  color: white;
}
.bold {
  font-weight: bolder;
}
.panel-screen {
  right: 0 !important;
}
@media screen and (max-width: 960px) {
  .panel-hidden {
    position: absolute;
    right: -100%;
    width: 87%;
    background: #f7f7f7;
    transition: 1s ease-out;
  }
  .hide-this-submit {
    display: none;
  }
}
</style>